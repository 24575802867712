//
// Rubik
//

// latin
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/rubik-regular.woff') format('woff2'),
    url('../fonts/rubik-regular.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/rubik-medium.woff') format('woff2'),
    url('../fonts/rubik-medium.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// latin-ext
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/rubik-regular-latin-ext.woff') format('woff2'),
    url('../fonts/rubik-regular-latin-ext.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF,
    U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/rubik-medium-latin-ext.woff') format('woff2'),
    url('../fonts/rubik-medium-latin-ext.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF,
    U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// cyrillic
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/rubik-regular-cyrillic.woff') format('woff2'),
    url('../fonts/rubik-regular-cyrillic.woff') format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/rubik-medium-cyrillic.woff') format('woff2'),
    url('../fonts/rubik-medium-cyrillic.woff') format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

// cyrillic-ext
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/rubik-regular-cyrillic-ext.woff') format('woff2'),
    url('../fonts/rubik-regular-cyrillic-ext.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/rubik-medium-cyrillic-ext.woff') format('woff2'),
    url('../fonts/rubik-medium-cyrillic-ext.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

//
// Roboto Mono
//

// latin
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/roboto-mono-regular.woff') format('woff2'),
    url('../fonts/roboto-mono-regular.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/roboto-mono-medium.woff') format('woff2'),
    url('../fonts/roboto-mono-medium.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// latin-ext
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/roboto-mono-regular-latin-ext.woff') format('woff2'),
    url('../fonts/roboto-mono-regular-latin-ext.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF,
    U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/roboto-mono-medium-latin-ext.woff') format('woff2'),
    url('../fonts/roboto-mono-medium-latin-ext.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF,
    U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// cyrillic
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/roboto-mono-regular-cyrillic.woff') format('woff2'),
    url('../fonts/roboto-mono-regular-cyrillic.woff') format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/roboto-mono-medium-cyrillic.woff') format('woff2'),
    url('../fonts/roboto-mono-medium-cyrillic.woff') format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

// cyrillic-ext
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/roboto-mono-regular-cyrillic-ext.woff') format('woff2'),
    url('../fonts/roboto-mono-regular-cyrillic-ext.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/roboto-mono-medium-cyrillic-ext.woff') format('woff2'),
    url('../fonts/roboto-mono-medium-cyrillic-ext.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

// Sentry icon font

@font-face {
  font-family: 'sentry-simple';
  src: url('../fonts/sentry-simple.eot?-iwi56z');
  src: url('../fonts/sentry-simple.eot?#iefix-iwi56z') format('embedded-opentype'),
    url('../fonts/sentry-simple.woff?-iwi56z') format('woff'),
    url('../fonts/sentry-simple.woff2?-lhf5py') format('woff2'),
    url('../fonts/sentry-simple.ttf?-iwi56z') format('truetype'),
    url('../fonts/sentry-simple.svg?-iwi56z#sentry-simple') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'],
.icon {
  font-family: 'sentry-simple';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Font based icons still in uses in Django rendered views.
.icon-sentry-logo:before {
  content: '\e61a';
}
.icon-sentry-logo-full:before {
  content: '\e623';
}
.icon-exclamation:before {
  content: '\e615';
}
.icon-arrow-left:before {
  content: '\e60e';
}
.icon-arrow-right:before {
  content: '\e611';
}

// Used by plugins in getsentry
.icon-checkmark:before {
  content: '\e60a';
}
.icon-lock:before {
  content: '\e007';
}
