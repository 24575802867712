// Alignment
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}

.align-left {
  text-align: left;
  justify-content: flex-start;
}

.align-center {
  text-align: center;
  justify-content: center;
}

.align-right {
  text-align: right;
  justify-content: flex-end;
}
