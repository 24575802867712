/**
 * Icons
 * ============================================================================
 */

.icon-arrow-right,
.icon-arrow-left {
  font-size: 16px !important;
  position: relative;
  top: 2px;
}
