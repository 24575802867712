// Browser icons

.icon-android {
  background-image: url(~sentry-logos/logo-android.svg);
}

.icon-chrome {
  background-image: url(~sentry-logos/logo-chrome.svg);
}

.icon-edge {
  background-image: url(~sentry-logos/logo-edge-new.svg);
}

.icon-internet-explorer {
  background-image: url(~sentry-logos/logo-ie.svg);
}

.icon-opera {
  background-image: url(~sentry-logos/logo-opera.svg);
}

.icon-safari {
  background-image: url(~sentry-logos/logo-safari.svg);
}
